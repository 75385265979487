\<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="분류코드"
            name="pipeTypeCd"
            v-model="searchParam.pipeTypeCd">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="유체의명칭또는구분"
            name="pipeName"
            v-model="searchParam.pipeName">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-process
            label="관련공정"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            label="관리부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="pipegasket"
          title="배관 및 개스킷 목록"
          tableId="pipegasket"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :merge="grid.merge"
          rowKey="psiPipeGasketId"
          :columnSetting="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="등록" icon="add" @btnClicked="pipegasketPopup" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'piping-gaskets',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'pipeTypeCd' },
          { index: 1, colName: 'pipeTypeCd' },
          // { index: 2, colName: 'pipeTypeCd' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          // {
          //   name: 'revisionNum',
          //   field: 'revisionNum',
          //   label: 'Rev.',
          //   align: 'center',
          //   style: 'width:50px',
          //   sortable: true,
          // },
          {
            name: 'pipeTypeCd',
            field: 'pipeTypeCd',
            label: '분류코드',
            align: 'center',
            type: 'link',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'pipeName',
            field: 'pipeName',
            label: '유체의명칭 또는 구분',
            style: 'width:200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'designPress',
            field: 'designPress',
            label: '설계압력(MPa)',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'designTemp',
            field: 'designTemp',
            label: '설계온도(℃)',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'pipeMaterial',
            field: 'pipeMaterial',
            label: '배관재질',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'gasketMaterial',
            field: 'gasketMaterial',
            label: '개스킷 재질 및 형태',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'noneDestructPercent',
            field: 'noneDestructPercent',
            label: '비파괴검사율',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'afterHeatTreatFlag',
            field: 'afterHeatTreatFlag',
            label: '후열처리여부',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        deviceName: '',
        pipeTypeCd: '',
        pipeName: '',
        processCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.psi.pfi.pipegasket.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.pipegasketPopup(row);
    },
    pipegasketPopup(result) {
      this.popupOptions.target = () => import(`${"./pipingGasketsDetail.vue"}`);
      this.popupOptions.title = '배관 및 개스킷 상세';
      this.popupOptions.param = {
        psiPipeGasketId: result ? result.psiPipeGasketId : '',
      };
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
